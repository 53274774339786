.firstBlog{
    font-family: "Albert Sans", "sans-serif";
}
.hashnodeBtn{
    background-color: #2a52be;
    color:white;
    padding: 6px;
    font-family:"Albert Sans";
    border-radius: 6px;
}
.substackBtn{
    color:white;
    padding: 6px;
    font-family:"Albert Sans";
    border-radius: 6px;
}
.content-container {
    max-width: 800px;
    margin: 0 auto; /* Center the content horizontally */
    word-wrap: break-word;
  }

  code {
    white-space : pre-wrap !important;
    font-family: "Albert Sans", sans-serif;
    line-height: 26px;
    font-size: 17px;
  }

  .redirect-back-chevron{
    margin: 8px 4px;
  }
