.mainName {
  font-size: 34px;
  text-align: left;
  font-family: "Albert Sans";
  margin: 20px 16px;
}
.about-me {
  font-size: 18px;
  text-align: left;
  font-family: "Albert Sans";
}

.footer--pin {
  margin-top: auto;
}

.MyImage {
  height: 160px;
  width: 160px;
  border-radius: 22px;
  transition: transform 0.3s ease;
}
.MyImage:hover {
  transform: scale(1.05);
}
.homepage-section {
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
}

.blog-links {
  color: black;
  text-decoration: underline;
  text-decoration-color: #71717a;
}
.blog-links:hover {
  color: #71717a;
}
.blog-links .fa-arrow-right {
  font-weight: 100;
}

.blog-description {
  margin: 4px 0px 14px 0px;
}
.home-sections {
  text-align: left;
  width: 100%;
}

@media only screen and (min-width: 800px) {
  .mainBody {
    width: 860px;
  }
  .mainDiv {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 876px) {
  .MyImage {
    margin-left: 36px;
    height: 120px;
    width: 120px;
  }
  .home-sections {
    margin: 20px 38px;
    text-align: left;
    width: 100%;
  }
  .mainName {
    margin-top: 8px;
    font-size: 30px;
    margin: 20px 38px;
  }
}
