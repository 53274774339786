.navlinks {
  margin: 16px 12px 6px 12px;
  padding: 6px;
}
.active {
  border-radius: 8px;
}

.navlinks a {
  text-decoration: none;
  position: relative;
  transition: all 0.3s ease;
}

.navlinks a:before {
  content: "";
  position: absolute;
  width: 0%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #000; /* Adjust color as needed */
  transition: all 0.3s ease;
}

.navlinks a:hover:before {
  width: 100%;
}

.navlinks a.active:before {
  width: 100%;
}
